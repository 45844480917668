import React, { Component } from "react";

const defaultState = {
  dark: true,
  notFound: false,
  toggleDark: () => {}
};

const ThemeContext = React.createContext(defaultState);

class ThemeProvider extends Component {
  state = {
    dark: true,
    notFound: false
  };

  componentDidMount() {
    //const lsDark = JSON.parse(localStorage.getItem("dark"));
    // if (lsDark) {
    //   this.setState({ dark: lsDark });
    // }
  }

  componentDidUpdate(prevState) {
    // const { dark } = this.state;
    // if (prevState.dark !== dark) {
    //   localStorage.setItem("dark", JSON.stringify(dark));
    // }
  }

  // toggleDark = () => {
  //   this.setState(prevState => ({ dark: !prevState.dark }));
  // };

  setNotFound = () => {
    this.setState({ notFound: true });
  };

  setFound = () => {
    this.setState({ notFound: false });
  };

  render() {
    const { children } = this.props;
    const { dark, notFound } = this.state;

    return (
      <ThemeContext.Provider
        value={{
          dark,
          notFound,
          setFound: this.setFound,
          setNotFound: this.setNotFound,
          toggleDark: true
        }}
      >
        {children}
      </ThemeContext.Provider>
    );
  }
}

export default ThemeContext;

export { ThemeProvider };
